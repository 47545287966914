<template>
  <div id="Student">
    <!-- <NelFundTop v-show="!this.$store.state.webview" /> -->
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="/">
        <img alt="NelFund Logo" src="../assets/nelfund-logo.png" width="150" />
      </b-navbar-brand>

      <b-navbar-toggle style="color: #ffffff;" target="nav-collapse" v-show="!this.$store.state.webview"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link to="/create" class="nav-link">
              <b-icon-sticky></b-icon-sticky>
              Open New Ticket
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/my-tickets" class="nav-link">
              <b-icon-stickies></b-icon-stickies>
              My Tickets
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/faq" class="nav-link">
              <b-icon-question-circle></b-icon-question-circle>
              FAQs
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <b-icon-box-arrow-in-right></b-icon-box-arrow-in-right>
              Login
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <!-- <b-navbar-nav class="ml-auto">

        </b-navbar-nav> -->
      </b-collapse>
    </b-navbar>

    <!-- <div style="width: 100%; height: auto; margin-top: 70px;"> -->
    <router-view />
    <!-- </div> -->
  </div>
</template>

<script>
import NelFundTop from '@/components/NelFundTop.vue'
export default {
  name: 'StudentLayout',
  components: {
    NelFundTop
  },
  computed: {
    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style scoped></style>
